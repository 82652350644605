<template>
  <div
    class="searchFilters"
  >
    <v-text-field
      v-if="showSearch"
      v-model="filterInput"
      :label="searchType"
      outlined
      ref="searchContent"
      clearable
      clear-icon="mdi-close-circle"
      single-line
      dense
      filled
      color="gray"
      hide-details
      autofocus
      full-width
      tabindex="0"
      @keyup="searchData()"
      @click:clear="clearInput()"
    >
      <v-icon
        slot="prepend-inner"
        color="gray"
        @click="resetSearch()"
        aria-label="reset"
      >
        arrow_back
      </v-icon>
    </v-text-field>
    <span
      class="searchClick"
      v-if="!showSearch"
    >
      <span
        role="heading"
        :aria-label="title"
        class="appTitle pt-n2"
        aria-level="1"
      >{{ this.title }}
        <span
          class="searchIcon mt-n3"
        >
          <v-icon
            :aria-label="searchIcon == 'search' ? 'Search' : 'filter results'"
            @click="searchClick"
            style="width:48px;height:48px"
            tabindex="0"
          >{{ this.searchIcon }}</v-icon>
        </span> 
      </span>
    </span>
  </div>
</template>
      
<script>
  export default {
    name: "SearchFilter",
    props: {
      searchDialog: {
        type: Function,
        default: null
      },
      type: {
        type: String,
        default:null
      },
      showInput:{
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ""
      },
      placeholder: {
        type: String,
        default: ""
      },
      title: {
        type: String,
        default: ""
      },
      searchIcon: {
        type: String,
        default:'search'
      }
    },
    computed: {      
      searchType(){
        return this.label ? this.label : 'Search '+ this.type;
      },
      showSearch(){
        return this.showInputField || this.showInput ? true : false ;
        }
    },
    data(){
      return{
        showInputField: this.showInput ? this.showInput : false,
        filterInput: ''
      }
    },
    methods:{
      searchData: function(){
         let filterValue =this.$refs.searchContent.lazyValue;
         if(filterValue){
           filterValue = filterValue.toLowerCase();
         }
         this.$store.dispatch("setFilterValue",{[this.type]:filterValue});
        },
        clearInput: function(){ this.$refs.searchContent.lazyValue = '';this.filterInput=''; this.searchData()},
        searchClick: function(){
          if(!this.searchDialog){
          this.showInputField = true;
          }else{
            this.searchDialog();
          }
        },
        resetSearch(){
          this.showInputField = false;
          this.clearInput();
          this.$emit("hideSearchField");
        }
    }
  }
</script>

<style scoped>
::v-deep .v-input__prepend-inner{
  margin-top:0px !important;
  padding-top:0px !important;
  padding-left:8px;
  padding-right:0px !important;
  width: 48px;
  height: 48px;
}
::v-deep .v-input__append-inner{
  margin-top:0px !important;
  padding-top:12px !important;
  padding-right:0px !important;
  width: 48px;
  height: 48px;
}
::v-deep .v-text-field input{
  padding:0px;
}
::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot{
  padding:0px !important;
}
::v-deep .v-text-field--filled.v-input--dense.v-text-field--single-line .v-label, .v-text-field--full-width.v-input--dense.v-text-field--single-line .v-label{
  top:14px;
}
::v-deep .v-input__slot{
  padding-left:0px !important;
}
::v-deep .appTitle {
height: 24px;
font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
color: #FFFFFF;
align-self: center;
margin: 16px 0px !important;
width:100%;
}
::v-deep .searchButton{
  height:16px !important;
  width:16px !important;
  right:12px !important;
  order:1;
  width:30%;
}
::v-deep .searchClick{
  display: flex;
  padding-left:8px !important;
  padding-bottom:0px !important;
}
::v-deep .searchIcon{
  float:right;
}
.searchFilters{
  flex-grow: 1 !important;
}

</style>