<template>
  <div>
    <appBarMobile
      color="primary"
      show-back-button
      :show-nav-icon="false"
      title="Contact Us"
    />
    <span
      class="ma-0 staticPages"
    >
      <v-card
        flat
        style="text-align:center;"
        class="flex pb-5"
      >
        <div
          class="pt-0"
          role="background"
          style="background:#F4F4F7;"
        >
          <span
            class="pt-4
         pb-7 flex-start"
          >
            <img
              alt="contact us image"
              src="../../public/assets/contactus.svg"
            >
          </span>
        </div>
      </v-card>
      <v-card flat>
        <v-card-title class="Heading pa-0">Urgent Queries</v-card-title>
        <v-card-text class="Item pa-0 pt-5 text--default">Please call EDConnect on <a
          style="text-decoration:none"
          href="#"
          @click="showPhone('1300323232')"
        >1300 32 32 32</a> during business hours for assistance with the Staff Portal app.</v-card-text>
      </v-card>
      <v-card
        flat
        class="pt-2"
      >
        <v-card-title class="Heading pa-0 pt-5">Non-urgent queries or feedback</v-card-title>
        <v-card-text class="Item pa-0 pt-5 text--default">Please go back and select "feedback" on the previous screen to send an email to the dedicated mailbox.</v-card-text>
      </v-card>
    </span>
  </div>
</template>
<script>
  import appBarMobile from "@/components/appBarMobile";
  export default {
    created(){
      window.scrollTo(0,0);
    },
    components: {
      appBarMobile,
    },
    methods:{
      showPhone(number){
        // eslint-disable-next-line
        NativeJS.tel(number);
      }
    }
  }
</script>
<style scoped>
::v-deep .theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle{
  color:#121212;
}
  .Heading{
  font-size:20px;
  font-weight: 500;
}
.Item{
  font-size:14px;
  font-weight: normal;
}
</style>